import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import { action } from '@ember/object';
import SecuredRouteMixin from 'mon-pix/mixins/secured-route-mixin';

export default class IndexRoute extends Route.extend(SecuredRouteMixin) {
    @tracked index = false;

    async model() {

        //const areas = await this.store.findAll('area', { include: 'resultCompetences' });
        const chapters = await this.store.findAll('chapter', { include: 'tutorials'});
        //return areas.sortBy('code');
        return chapters.sortBy('title');
    }

    @action
    toggleCompetencesVisibility(index){
        console.log(index);
    }

}

import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"background-banner-v2\">\n  <div class=\"user-tutorials-banner-v2\">\n    <h1 class=\"user-tutorials-banner-v2__title\">{{t \"pages.user-tutorials-v2.title\"}}</h1>\n    <p class=\"user-tutorials-banner-v2__description\">\n      {{t \"pages.user-tutorials-v2.description\"}}\n    </p>\n\n    <div class=\"user-tutorials-banner-v2__filters\">\n      <PixButtonLink @route=\"user-tutorials-v2.recommended\" @shape=\"rounded\" @backgroundColor={{this.isRecommended}}>\n        {{t \"pages.user-tutorials-v2.recommended\"}}\n      </PixButtonLink>\n\n      <PixButtonLink @route=\"user-tutorials-v2.saved\" @shape=\"rounded\" @backgroundColor={{this.isSaved}}>\n        {{t \"pages.user-tutorials-v2.saved\"}}\n      </PixButtonLink>\n    </div>\n  </div>\n</header>", {"contents":"<header class=\"background-banner-v2\">\n  <div class=\"user-tutorials-banner-v2\">\n    <h1 class=\"user-tutorials-banner-v2__title\">{{t \"pages.user-tutorials-v2.title\"}}</h1>\n    <p class=\"user-tutorials-banner-v2__description\">\n      {{t \"pages.user-tutorials-v2.description\"}}\n    </p>\n\n    <div class=\"user-tutorials-banner-v2__filters\">\n      <PixButtonLink @route=\"user-tutorials-v2.recommended\" @shape=\"rounded\" @backgroundColor={{this.isRecommended}}>\n        {{t \"pages.user-tutorials-v2.recommended\"}}\n      </PixButtonLink>\n\n      <PixButtonLink @route=\"user-tutorials-v2.saved\" @shape=\"rounded\" @backgroundColor={{this.isSaved}}>\n        {{t \"pages.user-tutorials-v2.saved\"}}\n      </PixButtonLink>\n    </div>\n  </div>\n</header>","moduleName":"mon-pix/components/tutorials/header.hbs","parseOptions":{"srcName":"mon-pix/components/tutorials/header.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Header extends Component {
  @service router;

  get isRecommended() {
    return this._isActive('user-tutorials-v2.recommended');
  }
  get isSaved() {
    return this._isActive('user-tutorials-v2.saved');
  }
  _isActive(route) {
    return route === this.router.currentRouteName ? 'grey' : 'transparent-light';
  }
}

/* eslint ember/no-computed-properties-in-native-classes: 0 */

import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';

export default class Tutorial extends Model {
  // attributes
  @attr('string') title;
  @attr('string') subtitle;

  @hasMany('tutorial') tutorials;




}

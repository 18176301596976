import Route from '@ember/routing/route';


export default class ResumeRoute extends Route {
  model(params) {

    const tutorial = this.store.findRecord('tutorial', params.id);

    return tutorial;
  }
}

import Route from '@ember/routing/route';

export default class CoursesRoute extends Route {

    async model() {

        const areas = await this.store.findAll('area', { include: 'resultCompetences' });
        console.log(areas);
        return areas;

        /*const tutorials = await this.store.findAll('tutorial', { reload: true });
        console.log(tutorials);
        return tutorials;*/

        //http://localhost:3002/api/airtable/content/Tutoriels
    }
}

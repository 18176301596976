import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isEnabled}}\n  <div class=\"communication-banner communication-banner--{{this.bannerType}}\">\n    <span class=\"communication-banner__icon\">\n      {{#if this.isError}}\n        <FaIcon @icon=\"exclamation-triangle\" />\n      {{else if this.isWarning}}\n        <FaIcon @icon=\"exclamation-circle\" />\n      {{else}}\n        <FaIcon @icon=\"info-circle\" />\n      {{/if}}\n    </span>\n    {{this.bannerContent}}\n  </div>\n{{/if}}", {"contents":"{{#if this.isEnabled}}\n  <div class=\"communication-banner communication-banner--{{this.bannerType}}\">\n    <span class=\"communication-banner__icon\">\n      {{#if this.isError}}\n        <FaIcon @icon=\"exclamation-triangle\" />\n      {{else if this.isWarning}}\n        <FaIcon @icon=\"exclamation-circle\" />\n      {{else}}\n        <FaIcon @icon=\"info-circle\" />\n      {{/if}}\n    </span>\n    {{this.bannerContent}}\n  </div>\n{{/if}}","moduleName":"mon-pix/components/communication-banner.hbs","parseOptions":{"srcName":"mon-pix/components/communication-banner.hbs"}});
import { htmlSafe } from '@ember/string';
import Component from '@glimmer/component';
import isEmpty from 'lodash/isEmpty';
import ENV from 'mon-pix/config/environment';

export default class CommunicationBanner extends Component {
  bannerType = ENV.APP.BANNER_TYPE;

  _rawBannerContent = ENV.APP.BANNER_CONTENT;
  _bannerTypes = {
    info: 'info',
    warning: 'warning',
    error: 'error',
  };

  get isEnabled() {
    return !isEmpty(this._rawBannerContent) && !isEmpty(this.bannerType);
  }

  get bannerContent() {
    return htmlSafe(this._rawBannerContent);
  }

  get isWarning() {
    return this.bannerType === this._bannerTypes.warning;
  }

  get isError() {
    return this.bannerType === this._bannerTypes.error;
  }
}

import Route from '@ember/routing/route';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import fetch from 'fetch'

export default class HomeRoute extends Route {
  async model() {
    const request = await fetch('https://app.bimer.education/items/homepage?fields=*.*')
    const { data } = await request.json()
    
    return {
      firstSlider: data.slider_1.map(slide => slide.directus_files_id),
      secondSlider: data.slider_2.map(slide => slide.directus_files_id),
      thirdSlider: data.slider_3.map(slide => slide.directus_files_id)
    }
  }
}

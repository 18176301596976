define("mon-pix/helpers/if-key", ["exports", "ember-keyboard/helpers/if-key"], function (_exports, _ifKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ifKey.default;
    }
  });
  Object.defineProperty(_exports, "ifKey", {
    enumerable: true,
    get: function () {
      return _ifKey.ifKey;
    }
  });
});

import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Skiplink @href=\"#main\" @label={{t \"common.skip-links.skip-to-content\"}} />\n<Skiplink @href=\"#footer\" @label={{t \"common.skip-links.skip-to-footer\"}} />\n\n<nav class=\"navbar-header\">\n  {{#if (media \"isDesktop\")}}\n    <NavbarDesktopHeader @shouldShowTheMarianneLogo={{this.isFrenchDomainExtension}} />\n  {{else}}\n    <NavbarMobileHeader @burger={{@burger}} @shouldShowTheMarianneLogo={{this.isFrenchDomainExtension}} />\n  {{/if}}\n</nav>", {"contents":"<Skiplink @href=\"#main\" @label={{t \"common.skip-links.skip-to-content\"}} />\n<Skiplink @href=\"#footer\" @label={{t \"common.skip-links.skip-to-footer\"}} />\n\n<nav class=\"navbar-header\">\n  {{#if (media \"isDesktop\")}}\n    <NavbarDesktopHeader @shouldShowTheMarianneLogo={{this.isFrenchDomainExtension}} />\n  {{else}}\n    <NavbarMobileHeader @burger={{@burger}} @shouldShowTheMarianneLogo={{this.isFrenchDomainExtension}} />\n  {{/if}}\n</nav>","moduleName":"mon-pix/components/navbar-header.hbs","parseOptions":{"srcName":"mon-pix/components/navbar-header.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class NavbarHeader extends Component {
  @service url;

  get isFrenchDomainExtension() {
    return this.url.isFrenchDomainExtension;
  }
}
